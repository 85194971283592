import { useEffect, useState } from "react";
import logo from "./assets/images/logo2.svg";
import Loader from "./components/shared/Loader";

function App() {
  const targetDate = new Date("2024-11-25T00:00:00");
  const [loading, setLoading] = useState(true);

  const [timeLeft, setTimeLeft] = useState({});

  const calculateTimeLeft = () => {
    setLoading(true);
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      setTimeLeft({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    } else {
      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });
    }
    setLoading(false);
  };

  const isToday = (targetDate) => {
    const today = new Date();
    const target = new Date(targetDate);

    return (
      today.getFullYear() === target.getFullYear() &&
      today.getMonth() === target.getMonth() &&
      today.getDate() === target.getDate()
    );
  };

  useEffect(() => {
    const interval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="App">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="heroTitle">COMING SoON</h1>
          <p className="moto">Beauty Starts Here – Get Ready!</p>

          {isToday(targetDate) ? (
            <></>
          ) : (
            <div className="countDown">
              <div className="countDownNumbers">
                <span>{timeLeft.days}</span> <p>Days</p>
              </div>
              <div className="countDownNumbers">
                <span>{timeLeft.hours}</span> <p>Hours</p>
              </div>
              <div className="countDownNumbers">
                <span>{timeLeft.minutes}</span> <p>Minutes</p>
              </div>
              <div className="countDownNumbers">
                <span>{timeLeft.seconds}</span> <p>Seconds</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default App;
